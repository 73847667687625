import { Alert } from 'reactstrap'

import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getStates } from './select-queries.graphql'

const StatesSelect = ({ federal_state, setFederalState, locale }) => {
  const { loading, error, data } = useQuery(getStates, { variables: { country_code: 'DE' } })

  if (error) {
    console.error('Error fetching possible States', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching possible states
      </Alert>
    )
  }

  const options =
    data?.possible_states?.map(federal_state => ({
      value: federal_state.key,
      label: federal_state.label
    })) || []

  options.unshift({
    value: 'other',
    label: 'Sonstige'
  })

  const value = options.find(option => option.value === federal_state)

  const onChange = selectedOption => {
    console.log(federal_state)
    setFederalState(selectedOption.value)
  }

  return (
    <>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        placeholder="Bitte auswählen..."
      />
    </>
  )
}

export default withLocale(StatesSelect, { key: 'statesSelect' })
