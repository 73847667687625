import React from 'react'

import { Alert, Col, Label } from 'reactstrap'

import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacility } from '../../facility.graphql'
import { updateChargesList } from './information.graphql'

import { convertToEuroString } from '../../../../helper/helper-functions'
import ValidatedInformationInput from '../../../../modules/inputs/validated-information-input'
import { FormGroupContainer, FormGroupContainerActions } from '../partials'
import EstimatedFacilitySizesSelect from 'components/modules/selects/estimatedFacilitySizesSelect'
import estimatedFacilitySizesSelect from 'components/modules/selects/estimatedFacilitySizesSelect'

const ChargesForm = props => (
  <div className="box">
    <Form {...props} />
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      variables: {
        chargesList: {
          facility_uuid: this?.props?.facility.uuid,
          certification: convertToEuroString(this?.props?.facility?.chargesList?.certification || 0),
          collection: convertToEuroString(this?.props?.facility?.chargesList?.collection || 0),
          discount: convertToEuroString(this?.props?.facility?.chargesList?.discount || 0),
          organisation: convertToEuroString(this?.props?.facility?.chargesList?.organisation || 0),
          other: convertToEuroString(this?.props?.facility?.chargesList?.other || 0),
          estimatedFacilitySize: this?.props?.facility?.estimatedFacilitySize || '-'
        }
      }
    }
  }

  convertToInt = value => {
    let checkEmpty = value === '' ? '0' : Number.parseFloat(value.split(',').join('.')).toFixed(2)
    return String(value).indexOf(',') !== -1 ? parseInt(checkEmpty * 100, 10) : parseInt(checkEmpty, 10) * 100
  }

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onChangeFacilitySize = facilitySize => {
    this.setState(update(this.state, { variables: { chargesList: { estimatedFacilitySize: { '$set': facilitySize } } } }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onSuccess = () =>
    this.setState(
      {
        processing: false,
        error_msg: '',
        errors: {}
      },
      this.props.hideEditBoxRight()
    )

  submit = () => {
    this.setState({ processing: true })
    const {
      convertToInt,
      props: {
        client,
        facility: { uuid }
      },
      state: {
        variables: { chargesList }
      }
    } = this

    const charges = {
      facilityUuid: chargesList.facility_uuid,
      prices: {
        certification: convertToInt(chargesList.certification),
        collection: convertToInt(chargesList.collection),
        discountAndFunding: convertToInt(chargesList.discount),
        organisation: convertToInt(chargesList.organisation),
        other: convertToInt(chargesList.other)
      },
      facilitySize: chargesList.estimatedFacilitySize
    }

    client
      .mutate({
        mutation: updateChargesList,
        variables: { input: charges },
        refetchQueries: [
          {
            query: getFacility,
            variables: {
              uuid,
              version_number: this.props.match.params.version_number || ''
            }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.onSuccess())
      .catch(error => this.onError(error))
  }

  render = () => {
    const { onChange, submit, onChangeFacilitySize } = this
    const { locale, hideEditBoxRight } = this.props
    const { variables, errors, error_msg, processing } = this.state

    const input_props = { errors, variables, locale, onChange }
    const actions_props = { locale, hideEditBoxRight, processing, submit }

    const idForSelect = 'chargesList.estimatedFacilitySize'

    return (
      <React.Fragment>
        {error_msg && <Alert color="danger">{error_msg}</Alert>}

        <FormGroupContainer>
          <Col lg="5" className="text-lg-right">
            <Label className="control-label" for={idForSelect}>
              {locale.estimatedFacilitySizesSelect.label}
            </Label>
          </Col>
          <Col lg="7">
            <EstimatedFacilitySizesSelect
              id={idForSelect}
              facilitySize={variables.chargesList.estimatedFacilitySize}
              setFacilitySize={onChangeFacilitySize}
              locale={locale}
            />
          </Col>
        </FormGroupContainer>

        <FormGroupContainer children={<ValidatedInformationInput id={'chargesList.collection'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'chargesList.certification'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'chargesList.organisation'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'chargesList.other'} {...input_props} />} />
        <FormGroupContainer children={<ValidatedInformationInput id={'chargesList.discount'} {...input_props} />} />
        <FormGroupContainerActions {...actions_props} />
      </React.Fragment>
    )
  }
}

export default withApollo(ChargesForm)
