import { Alert } from 'reactstrap'

import { useQuery } from '@apollo/client'

import { Select } from 'components/modules/selects/react-select'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import { getFacilitySizes } from './estimatedFacilitySizesSelect.graphql'

const EstimatedFacilitySizesSelect = ({ facilitySize, setFacilitySize, locale }) => {
  const { loading, error, data } = useQuery(getFacilitySizes)

  if (error) {
    console.error('Error fetching facility sizes', error)
    return (
      <Alert color="danger" className="m-0">
        Error fetching facility sizes
      </Alert>
    )
  }

  const options =
    data?.possibleFacilitySizes?.map(facilitySize => ({
      value: facilitySize,
      label: facilitySize
    })) || []

  options.unshift({
    value: null,
    label: locale.none
  })

  const value = options.find(option => option.value === facilitySize)

  const onChange = selectedOption => {
    setFacilitySize(selectedOption.value)
  }

  return (
    <>
      <Select
        isLoading={loading}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={locale.none}
      />
    </>
  )
}

EstimatedFacilitySizesSelect.propTypes = {
  facilitySize: PropTypes.array.isRequired,
  setFacilitySize: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired
}

export default withLocale(EstimatedFacilitySizesSelect, { key: 'estimatedFacilitySizesSelect' })
