import React from 'react'

import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'

import { HelpTooltip } from 'components/views/facility/form-datum/help-tooltip'

import { ProcessingButton } from '../../../modules/buttons/processing-button'
import ValidatedInput from '../../../modules/inputs/validated-input'
import CountrySelect from '../../../modules/selects/countries'
import DefaultDsftUsers from '../../../modules/selects/default-dsft-user-select/default-dsft-users'
import DefaultUsers from '../../../modules/selects/default-user-select/default_users'
import LicenseOwners from '../../../modules/selects/license-owners-select/license_owners'
import OrganizationSelect from '../../../modules/selects/organization-select'
import Regions from '../../../modules/selects/regions-select/regions'
import Salutation from '../../../modules/selects/salutation-select/salutation'
import StateSelect from '../../../modules/selects/states'

const FormGroups = ({
  processing,
  id,
  response,
  variables,
  errors,
  onChange,
  addLogo,
  addSignature,
  changeContactSalutation,
  change,
  submit,
  changeLicenseOwner,
  changeRegions,
  locale,
  toggleModal,
  license_owner: { parent_id, contact_person, region_ids }
}) => {
  return (
    <React.Fragment>
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.name'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="license_owner.master">
                {locale.is_sublicense_owner}
              </Label>
            </Col>
            <Col sm="4">
              <LicenseOwners license_owner_id={parent_id} changeLicenseOwner={changeLicenseOwner} />
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="license_owner.region_ids">
                {locale.region.singular}
              </Label>
            </Col>
            <Col sm="4">
              <Regions region_ids={region_ids} changeRegions={changeRegions} />
            </Col>
          </Row>
        </Container>
      </FormGroup>

      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="default_dsft_user_id">
                {locale.default_dsft_user}
              </Label>
            </Col>
            <Col sm="4">
              {id ? (
                <DefaultDsftUsers value={variables.license_owner.default_dsft_user_id} change={change} id={id} />
              ) : (
                <p>Kann erst nach dem Speichern geändert werden.</p>
              )}
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="default_user_id">
                {locale.default_user}
              </Label>
            </Col>
            <Col sm="4">
              {id ? (
                <DefaultUsers value={variables.license_owner.default_user_id} change={change} id={id} />
              ) : (
                <p>Kann erst nach dem Speichern geändert werden.</p>
              )}
            </Col>
          </Row>
        </Container>
      </FormGroup>

      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="default_dsft_user_id">
                {locale.organizations}
                <HelpTooltip help_text={locale.license_owner.organization_help} />
              </Label>
            </Col>
            <Col sm="4">
              <OrganizationSelect
                value={variables.license_owner.organization_ids}
                change={onChange}
                id="license_owner.organization_ids"
              />
            </Col>
          </Row>
        </Container>
      </FormGroup>

      <h4>{locale.address}</h4>
      <hr className="seperator seperator-form border-gray" />
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.address.street'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
            <ValidatedInput
              id={'license_owner.address.street_addition'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.address.zipcode'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
            <ValidatedInput
              id={'license_owner.address.city'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="address_country_code">
                {locale.country}
              </Label>
            </Col>
            <Col sm="4">
              <CountrySelect id={'license_owner.address.country_code'} variables={variables} onChange={onChange} />
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="address_state">
                {locale.state}
              </Label>
            </Col>
            <Col sm="4">
              <StateSelect
                id={'license_owner.address.state'}
                errors={errors}
                current_country={variables.license_owner.address.country_code}
                variables={variables}
                onChange={onChange}
              />
            </Col>
          </Row>
        </Container>
      </FormGroup>
      <h4>{locale.contact_person}</h4>
      <hr className="seperator seperator-form border-gray" />
      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="salutation">
                {locale.salutation}
              </Label>
            </Col>
            <Col sm="4">
              <Salutation salutation={contact_person.salutation} onChange={changeContactSalutation} />
            </Col>
            <ValidatedInput
              id={'license_owner.contact_person.title'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.contact_person.first_name'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
            <ValidatedInput
              id={'license_owner.contact_person.last_name'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.contact_person.email'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
            <ValidatedInput
              id={'license_owner.contact_person.phone'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>

      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.contact_person.function'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>

      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.contact_person.address.street'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
            <ValidatedInput
              id={'license_owner.contact_person.address.street_addition'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              id={'license_owner.contact_person.address.zipcode'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
            <ValidatedInput
              id={'license_owner.contact_person.address.city'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
        </Container>
      </FormGroup>
      <FormGroup>
        <Container>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="state">
                {locale.country}
              </Label>
            </Col>
            <Col sm="4">
              <CountrySelect
                id={'license_owner.contact_person.address.country_code'}
                variables={variables}
                onChange={onChange}
              />
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="country_code">
                {locale.state}
              </Label>
            </Col>
            <Col sm="4">
              <StateSelect
                id={'license_owner.contact_person.address.state'}
                errors={errors}
                current_country={variables.license_owner.contact_person.address.country_code}
                variables={variables}
                onChange={onChange}
              />
            </Col>
          </Row>
        </Container>
      </FormGroup>
      <h4>{locale.license_owner.signature}</h4>
      <hr className="seperator seperator-form border-gray" />
      <FormGroup>
        <Container>
          <Row>
            <ValidatedInput
              style={{ style: { resize: 'none' } }}
              rows="3"
              type={'textarea'}
              id={'license_owner.signature_text'}
              errors={errors}
              variables={variables}
              onChange={onChange}
              locale={locale}
            />
          </Row>
          <Row>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="license_owner.signature_file">
                Signatur
              </Label>
            </Col>
            <Col sm="4">
              <Input
                id={'license_owner.signature_file'}
                accept={'.png,.jpg,.jpeg'}
                type="file"
                onChange={addSignature}
              />
              {response?.signature?.url?.thumb && <img src={response?.signature?.url?.thumb} />}
            </Col>
            <Col sm="2" className="text-right">
              <Label className="control-label" for="logo">
                Logo
              </Label>
            </Col>
            <Col sm="4">
              <Input id={'license_owner.logo'} accept={'.png,.jpg,.jpeg'} type="file" onChange={addLogo} />
              {response?.logo_url.thumb && <img src={response?.logo_url.thumb} />}
            </Col>
          </Row>
        </Container>
      </FormGroup>
      <FormGroup className="form-action">
        <Container>
          <Row>
            <Col sm="2">
              <a className="link-btn" onClick={toggleModal} href="#">
                {locale.cancel}
              </a>
            </Col>
            <Col sm={{ size: '4', offset: '6' }} className="text-right">
              <ProcessingButton label={locale.save} onClick={submit} processing={processing} />
            </Col>
          </Row>
        </Container>
      </FormGroup>
    </React.Fragment>
  )
}
export default FormGroups
