import { Component, Fragment } from 'react'

import { Alert, Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LegacySelect as Select } from 'components/modules/selects/react-select'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createFacility, getNewFacility } from './create_facility.graphql'

import { getPropertyFromObject } from '../../helper/helper-functions'
import Loading from '../../helper/loading/loading'
import ValidatedInput from '../../modules/inputs/validated-input'
import SelectCountry from '../../modules/stateless-selects/select-country'
import { Facilitykind } from '../facilities/form/form-create'

const CreateFacility = ({ data: { loading, error, new_facility }, locale, client, history }) =>
  error ? (
    console.log(error)
  ) : loading ? (
    <Loading />
  ) : (
    <CreateFacilityForm new_facility={new_facility} locale={locale} client={client} history={history} />
  )

class CreateFacilityForm extends Component {
  constructor(props) {
    super(props)
    let kind_idOptions = []
    this.props.new_facility.base_data.possible_kinds.map(kind => {
      let subkind_options = []
      if (kind.sub_kinds.length) {
        kind.sub_kinds.map(subkind => {
          subkind_options.push({
            id: subkind.id,
            label: subkind.name_de,
            value: false
          })
        })
      }

      kind_idOptions.push({
        id: kind.id,
        label: kind.name_de,
        value: false,
        subkind_options
      })
    })

    this.state = {
      kind_idOptions,
      variables: {
        facility: {
          name_de: '',
          description_de: '',
          kind_ids: [],
          license_owner_id: '',
          contact_user: {
            email: '',
            email_notifications: true,
            first_name: '',
            last_name: '',
            salutation: 'mr',
            title: '',
            function: '',
            phone: '',
            password: '',
            password_confirmation: ''
          },
          privacy_policy_checked: false
        }
      }
    }
  }

  defaultState = () => {
    const variables = {
      facility: {
        name_de: '',
        description_de: '',
        kind_ids: [],
        license_owner_id: '',
        contact_user: {
          email: '',
          email_notifications: true,
          first_name: '',
          last_name: '',
          salutation: 'mr',
          title: '',
          function: '',
          phone: '',
          password: '',
          password_confirmation: ''
        },
        privacy_policy_checked: false
      }
    }
    this.setState({ variables })
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.checked, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  toggleSubkinds = event => {
    let sub_list = document.getElementById(`subkind-${event.target.id}`)

    if (sub_list !== null) {
      sub_list.classList.contains('d-none') ? sub_list.classList.remove('d-none') : sub_list.classList.add('d-none')
    }
  }

  changeCheckbox = event => {
    event.preventDefault()
    event.stopPropagation()
    this.toggleSubkinds(event)

    let add_kind_ids = this.state.variables.facility.kind_ids

    if (event.target.checked && this.state.variables.facility.kind_ids.indexOf(event.target.id) === -1) {
      add_kind_ids.push(event.target.id)

      this.setState(
        update(this.state, {
          variables: {
            facility: {
              kind_ids: { $set: add_kind_ids }
            }
          }
        })
      )
    } else {
      let kind_index = add_kind_ids.indexOf(event.target.id)

      if (kind_index !== -1) {
        add_kind_ids.splice(kind_index, 1)
      }

      this.setState(
        update(this.state, {
          variables: {
            facility: {
              kind_ids: { $set: add_kind_ids }
            }
          }
        })
      )
    }
  }

  submit = () =>
    this.props.client
      .mutate({
        mutation: createFacility,
        variables: this.state.variables
      })
      .then(result => this.props.history.push('login'))
      .catch(error => this.onError(error))

  render = () => {
    const { variables, errors, error_msg } = this.state
    const {
      locale,
      new_facility: {
        base_data: {
          address: { possible_countries },
          contact_person: { possible_salutations }
        },
        possible_license_owners
      }
    } = this.props
    const { onChange, onChangePrivacyPolicy, changeCheckbox, toggleSubkinds, submit } = this

    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col>
            <h1>Betrieb registrieren</h1>
          </Col>
        </Row>
        <hr className="seperator" />
        <div className="box">
          <Form>
            {errors ? <Alert color="danger">{error_msg}</Alert> : ''}
            <FormGroup>
              <Container>
                <Row>
                  <ValidatedInput
                    id={'facility.name_de'}
                    variables={variables}
                    errors={errors}
                    onChange={onChange}
                    locale={locale}
                  />

                  <Col sm="2" className="text-right">
                    <Label className="control-label" for="facility.license_owner_id">
                      {locale.license_owner.singular}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <Select
                      id="facility.license_owner_id"
                      placeholder={'optional'}
                      isClearable={false}
                      name="facility.license_owner_id"
                      value={getPropertyFromObject(variables, 'facility.license_owner_id')}
                      onChange={onChange}
                      options={possible_license_owners.map(possible_license_owner => {
                        return {
                          value: possible_license_owner.id,
                          label: possible_license_owner.name,
                          target: { id: 'facility.license_owner_id', value: possible_license_owner.id }
                        }
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <ValidatedInput
                    type={'textarea'}
                    id={'facility.description_de'}
                    variables={variables}
                    errors={errors}
                    onChange={onChange}
                    locale={locale}
                  />
                </Row>
                <Row>
                  <Col sm="2" className="text-right">
                    <p className="control-label">{locale.facility_kinds}</p>
                  </Col>
                  <Col sm="10">
                    <ul className="list-unstyled">
                      {this.state.kind_idOptions.map(facility_kind => (
                        <Facilitykind
                          key={facility_kind.id}
                          {...facility_kind}
                          changeCheckbox={changeCheckbox}
                          toggleSubkinds={toggleSubkinds}
                        />
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
            {false && (
              <Fragment>
                <h4>{locale.address}</h4>
                <hr className="seperator seperator-form border-gray" />
                <FormGroup>
                  <Container>
                    <Row>
                      <ValidatedInput
                        id={'facility.contact_user.address.street'}
                        errors={errors}
                        variables={variables}
                        onChange={onChange}
                        locale={locale}
                      />
                      <ValidatedInput
                        id={'facility.contact_user.address.street_addition'}
                        errors={errors}
                        variables={variables}
                        onChange={onChange}
                        locale={locale}
                      />
                    </Row>
                  </Container>
                </FormGroup>
                <FormGroup>
                  <Container>
                    <Row>
                      <ValidatedInput
                        id={'facility.contact_user.address.zipcode'}
                        errors={errors}
                        variables={variables}
                        onChange={onChange}
                        locale={locale}
                      />
                      <ValidatedInput
                        id={'facility.contact_user.address.city'}
                        errors={errors}
                        variables={variables}
                        onChange={onChange}
                        locale={locale}
                      />
                    </Row>
                  </Container>
                </FormGroup>
                <FormGroup>
                  <Container>
                    <Row>
                      <Col sm="2" className="text-right">
                        <Label className="control-label" for="facility.contact_user.address.country_code">
                          {locale.country}
                        </Label>
                      </Col>
                      <Col sm="4">
                        <SelectCountry
                          id={'facility.contact_user.address.country_code'}
                          variables={variables}
                          onChange={onChange}
                          possible_countries={possible_countries}
                        />
                      </Col>
                      <Col sm="2" className="text-right">
                        <Label className="control-label" for="facility.contact_user.address.state">
                          {locale.state}
                        </Label>
                      </Col>
                      <Col sm="4"></Col>
                    </Row>
                  </Container>
                </FormGroup>
              </Fragment>
            )}
            <h4>{locale.contact_person}</h4>
            <hr className="seperator seperator-form border-gray" />
            <FormGroup>
              <Container>
                <Row>
                  <Col sm="2" className="text-right">
                    <Label className="control-label" for="facility.contact_user.salutation">
                      {locale.salutation}
                    </Label>
                  </Col>
                  <Col sm="4">
                    <Select
                      id="facility.contact_user.salutation"
                      style={
                        getPropertyFromObject(errors, 'facility.contact_user.salutation') && {
                          borderColor: '#e53012'
                        }
                      }
                      placeholder={'auswählen'}
                      isClearable={false}
                      name="facility.contact_user.salutation"
                      value={getPropertyFromObject(variables, 'facility.contact_user.salutation')}
                      onChange={onChange}
                      options={possible_salutations.map(salutation => {
                        return {
                          value: salutation.key,
                          label: salutation.label,
                          target: { id: 'facility.contact_user.salutation', value: salutation.key }
                        }
                      })}
                    />
                    {!!getPropertyFromObject(errors, 'facility.contact_user.salutation') && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '80%',
                          color: '#e53012'
                        }}
                      >
                        {getPropertyFromObject(errors, 'facility.contact_user.salutation')}
                      </div>
                    )}
                  </Col>
                  <ValidatedInput
                    id={'facility.contact_user.title'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                </Row>
              </Container>
            </FormGroup>
            <FormGroup>
              <Container>
                <Row>
                  <ValidatedInput
                    id={'facility.contact_user.first_name'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                  <ValidatedInput
                    id={'facility.contact_user.last_name'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                </Row>
              </Container>
            </FormGroup>
            <FormGroup>
              <Container>
                <Row>
                  <ValidatedInput
                    id={'facility.contact_user.email'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                  <ValidatedInput
                    id={'facility.contact_user.phone'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                </Row>
              </Container>
            </FormGroup>
            <h4>{locale.facility.contact_user.password}</h4>
            <hr className="seperator seperator-form border-gray" />
            <FormGroup>
              <Container>
                <Row>
                  <ValidatedInput
                    type={'password'}
                    id={'facility.contact_user.password'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                  <ValidatedInput
                    type={'password'}
                    id={'facility.contact_user.password_confirmation'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                  />
                </Row>
              </Container>
            </FormGroup>
            <h4>{locale.facility.privacy_policy}</h4>
            <hr className="seperator seperator-form border-gray" />
            <FormGroup>
              <Container>
                <Row>
                  <ValidatedInput
                    id={'facility.privacy_policy_checked'}
                    type={'checkbox'}
                    errors={errors}
                    variables={variables}
                    onChange={onChange}
                    locale={locale}
                    customLabel={locale.facility.privacy_policy_checked}
                  />
                </Row>
              </Container>
            </FormGroup>
            <FormGroup className="form-action">
              <Container>
                <Row>
                  <Col sm="5">
                    <a
                      className="link-btn"
                      onClick={event => {
                        event.preventDefault()
                        this.props.history.push('/login')
                      }}
                      href=""
                    >
                      {locale.cancel}
                    </a>
                  </Col>
                  <Col sm="7" className="text-right">
                    <Button className="btn-labeled" color="primary-light" onClick={submit}>
                      <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                      <span>{locale.save}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
  }
}

export default withApollo(
  graphql(getNewFacility, {
    options: () => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  })(CreateFacility)
)
