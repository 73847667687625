// additional components
import React from 'react'

import { Alert } from 'reactstrap'

// GraphQL
import { withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { createLicenseOwner, getLicenseOwners } from './license-owners.graphql'

import FormGroups from './form'

const LicenseOwnerCreate = ({ locale, history, client, toggleModal }) => (
  <LicenseeForm locale={locale} client={client} history={history} toggleModal={toggleModal} />
)

class LicenseeForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      variables: {
        license_owner: {
          name: '',
          default_user_id: '',
          default_dsft_user_id: '',
          organization_ids: [],
          address: {
            street: '',
            street_addition: '',
            zipcode: '',
            city: '',
            country_code: 'DE',
            state: ''
          },
          allowsAutomatedTenderCreation: true,
          priceInfoText: '',
          contact_person: {
            salutation: 'mr',
            title: '',
            first_name: '',
            last_name: '',
            function: '',
            phone: '',
            email: '',
            address: {
              street: '',
              street_addition: '',
              zipcode: '',
              city: '',
              country_code: 'DE',
              state: ''
            }
          },
          region_ids: [],
          parent_id: ''
        }
      },
      error_msg: ''
    }
  }

  changeContactSalutation = salutation => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              salutation: {
                $set: salutation.value
              }
            }
          }
        }
      })
    )
  }

  changeContactPerson = event => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }

  changeContactPersonAddress = event => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              address: {
                [event.target.id]: {
                  $set: event.target.value
                }
              }
            }
          }
        }
      })
    )
  }

  changeLicenseOwner = license_owner => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            parent_id: {
              $set: license_owner.value
            }
          }
        }
      })
    )
  }

  changeRegions = region_ids => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            region_ids: {
              $set: region_ids ? region_ids.split(',') : []
            }
          }
        }
      })
    )
  }

    ChangeAllowsAutomatedTenderCreation = allowsAutomatedTenderCreation => {
      this.setState(
        update(this.state, {
          variables: {
            license_owner: {
              allowsAutomatedTenderCreation: true
            }
          }
        })
      )
    }


  changeLicenseOwnerAddress = event => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            address: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }

  changeLicenseOwnerAddressState = state => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            address: {
              state: {
                $set: state.value
              }
            }
          }
        }
      })
    )
  }
  changeLicenseOwnerAddressCountry = country => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            address: {
              country_code: {
                $set: country.value
              }
            }
          }
        }
      })
    )
  }

  changeContactAddressState = state => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              address: {
                state: {
                  $set: state.value
                }
              }
            }
          }
        }
      })
    )
  }

  changeContactAddressCountry = country => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: { // Is this important for state/region selection later or is it okay if your write name Italy but region Allgäu, Bundeland Bavaria for example ?
            contact_person: {
              address: {
                country_code: {
                  $set: country.value
                }
              }
            }
          }
        }
      })
    )
  }

  addSignature = e =>
    this.setState(update(this.state, { variables: { license_owner: { signature_file: { $set: e.target.files[0] } } } }))
  addLogo = e =>
    this.setState(update(this.state, { variables: { license_owner: { logo: { $set: e.target.files[0] } } } }))

  onChange = event => {
    const fragments = event.target.id.split('.')
    const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    this.setState({ processing: true })
    const { variables } = this.state
    this.props.client
      .mutate({
        mutation: createLicenseOwner,
        variables,
        refetchQueries: [
          {
            query: getLicenseOwners
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.setState({ processing: false }, this.props.toggleModal()))
      .catch(error => this.onError(error))
  }

  render = () => (
    <React.Fragment>
      {this.state.errors && <Alert color="danger">{this.state.error_msg}</Alert>}
      <FormGroups
        variables={this.state.variables}
        errors={this.state.errors}
        locale={this.props.locale}
        client={this.props.client}
        onChange={this.onChange}
        submit={this.submit}
        addLogo={this.addLogo}
        addSignature={this.addSignature}
        changeContactAddressCountry={this.changeContactAddressCountry}
        changeContactAddressState={this.changeContactAddressState}
        changeContactSalutation={this.changeContactSalutation}
        changeLicenseOwner={this.changeLicenseOwner}
        changeLicenseOwnerAddressCountry={this.changeLicenseOwnerAddressCountry}
        changeLicenseOwnerAddressState={this.changeLicenseOwnerAddressState}
        changeRegions={this.changeRegions}
        license_owner={this.state.variables.license_owner}
        processing={this.state.processing}
        toggleModal={this.props.toggleModal}
      />
    </React.Fragment>
  )
}

export default withApollo(LicenseOwnerCreate)
