import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

import { getOfflineFacilities } from './../facilities.graphql'

import { DD_MM_YYYY } from '../../../helper/helper-functions'
import Loading from '../../../helper/loading/loading'
import { ChargesString } from '../helper/ChargesString'
import FacilityNameWithBundleInfo from '../helper/facility_name_with_bundle_info'

const OfflineFacilities = ({ locale }) => (
  <Query fetchPolicy={'network-only'} query={getOfflineFacilities} variables={{ working_list: 'offline' }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />
      if (!data || !data.facilities || data.facilities.length === 0) return null
      if (error) return `Error!: ${error}`
      return (
        <Table striped responsive bordered style={{ whiteSpace: 'nowrap' }}>
          <thead className="thead-light">
            <tr>
              <th>{locale.facility_name}</th>
              <th>{locale.audit_assignment_number}</th>
              <th>{locale.license_owner.singular}</th>
              <th>{locale.region.singular}</th>
              <th>{locale.facility_kind.plural}</th>
              <th>
                {locale.date} {locale.inspection_order}
              </th>
              <th>{locale.collection_price}</th>
              <th>{locale.status}</th>
              <th>{locale.recertification}</th>
              <th>{locale.collection_scheduled}</th>
            </tr>
          </thead>
          <tbody>
            {data.facilities &&
              data.facilities.map(facility => <TableItem key={facility.uuid} locale={locale} facility={facility} />)}
          </tbody>
        </Table>
      )
    }}
  </Query>
)

const certification_label = certification_number => {
  if (certification_number === null) {
    return 'Erst-Zertifizierung'
  }
  return `${certification_number}. Zertifizierung`
}

const TableItem = ({ facility, locale }) => (
  <tr>
    <td>
      <Link to={`/offline-facility/${facility.uuid}`}>
        <FacilityNameWithBundleInfo
          name={facility.base_data.name_de}
          firstBundledFacility={
            facility.bundleInformation.bundledFacilities.nodes.slice(0, 1).map(bundledFacility => ({
              name: bundledFacility.base_data.name_de,
              audit_order_number: bundledFacility.certification_data.audit_order_number
            }))[0]
          }
          totalBundledFacilities={facility.bundleInformation.bundledFacilities.totalCount}
          firstBundledByFacility={
            facility.bundleInformation.bundledBy.nodes.slice(0, 1).map(bundledByFacility => ({
              name: bundledByFacility.base_data.name_de,
              audit_order_number: bundledByFacility.certification_data.audit_order_number
            }))[0]
          }
          totalBundledByFacilities={facility.bundleInformation.bundledBy.totalCount}
        />
      </Link>
    </td>
    <td>{facility?.certification_data?.audit_order_number ? facility.certification_data.audit_order_number : '-'}</td>
    <td>{facility.license_owner?.name || '-'}</td>
    <td>{facility.base_data.regions.map(region => region.name_de).join(', ')}</td>
    <td>{facility.base_data.kinds.map(kind => kind.name_de).join(', ')}</td>
    <td>{DD_MM_YYYY(facility?.collection_requested_at)}</td>
    <td>{ChargesString(facility.chargesList.collection)}</td>
    <td>{locale.certification_states[facility.current_workflow_state.state]}</td>
    <td>{certification_label(facility.certification_data.certification_number)}</td>
    <td>
      {facility.collection_desired_until !== null
        ? DD_MM_YYYY(facility.collection_desired_until)
        : 'keine Frist gesetzt'}
    </td>
  </tr>
)

export default withApollo(OfflineFacilities)
