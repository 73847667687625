import { useState } from 'react'

import { Button, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useMutation, useQuery } from '@apollo/client'

import Loading from 'components/helper/loading/loading'
import { withLocale } from 'locale'

import {
  getFacilitySizes,
  updateAutomatedTenderInformation
} from '../../views/admin/license-owners/license-owners.graphql'

import AdditionalInfoOnPricesInput from './additionalInfoOnPricesInput'
import LicenseOwnerPricesTable from './licenseOwnerPricesTable'

const AutomatedTendersCell = ({ license_owner, locale, generalLocale, tooltip_uuid }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const { loading, error, data } = useQuery(getFacilitySizes, {
    variables: {},
    notifyOnNetworkStatusChange: true
  })

  const mapData = originalData => {
    return originalData.map(element => {
      const { __typename, ...mappedElement } = element
      return mappedElement
    })
  }

  const [priceInfoText, setPriceInfoText] = useState(license_owner.priceInfoText)
  const [prices, setPrices] = useState(mapData(license_owner.licenseOwnerPrices))
  const [allowAutomatedTenderGeneration, setAllowAutomatTenderGeneration] = useState(
    license_owner.allowsAutomatedTenderCreation
  )

  const setPriceSpecialLogic = (current_price, type, facility_size) => {
    let newPrices = mapData(prices)
    const priceIndex = newPrices.findIndex(element => {
      return element.facilitySize == facility_size
    })
    newPrices[priceIndex][type] = parseFloat(current_price)
    setPrices(newPrices)
  }

  const [
    updateAutomatedTenderInformationMutation,
    { loading: loadingOfMutation, error: errorOfMutation, data: dataOfMutation }
  ] = useMutation(updateAutomatedTenderInformation, {
    variables: {
      licenseOwnerId: license_owner.id,
      prices: prices,
      allowsAutomatedTenderCreation: allowAutomatedTenderGeneration,
      priceInfoText: priceInfoText
    }
  })

  if (error || errorOfMutation) return `Errors! ${error}`

  return (
    <>
      <Button size={`sm`} className="link-btn" color={'link'} onClick={toggle} id={`tooltip-modal_${tooltip_uuid}`}>
        <p>{license_owner.allowsAutomatedTenderCreation ? generalLocale.yes : generalLocale.no}</p>
      </Button>
      <Modal centered isOpen={modal} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>Preise Bearbeiten für: {license_owner.name}</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loading />
          ) : (
            <>
              {
                // TODO; Für Checkbox die aus Steves commit nutzen
              }
              <InputGroup>
                <Label className="control-label" for="license_owner.allowsAutomatedTenderCreation">
                  {locale.allowSelfSignUp}
                </Label>
                <Input
                  id={'license_owner.allowsAutomatedTenderCreation'}
                  type="checkbox"
                  defaultChecked={license_owner.allowsAutomatedTenderCreation}
                  onChange={event => {
                    setAllowAutomatTenderGeneration(event.target.checked)
                  }}
                />
              </InputGroup>
              <hr className="seperator border-gray" />
              <LicenseOwnerPricesTable
                license_owner={license_owner}
                prices={prices}
                facility_sizes={data}
                onChange={setPriceSpecialLogic}
              />
              <AdditionalInfoOnPricesInput priceInfoText={priceInfoText} onChange={setPriceInfoText} />
            </>
          )}
          {loadingOfMutation && <p>{generalLocale.saving}</p>}
          {dataOfMutation && <p>{generalLocale.saved}</p>}
          {errorOfMutation && <p>{generalLocale.saving_problem}</p>}
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={toggle}>
            {generalLocale.close}
          </Button>
          <Button onClick={updateAutomatedTenderInformationMutation}>{generalLocale.save}</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withLocale(withLocale(AutomatedTendersCell, { key: 'licenseOwners.automatedTendersInformation' }), {
  attributeName: 'generalLocale'
})
