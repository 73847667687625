import React from 'react'

import { Badge, Button, Collapse, Table, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { convertToEuroString } from '../../../../helper/helper-functions'
import { TableField } from '../partials'

class Charges extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  estimatedFacilitySizeInformation = (facilitySize) => {
    if (facilitySize === null || facilitySize === undefined) {
      return '-'
    }

    return <Badge pill color="info">{facilitySize}</Badge>
  }

  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      estimatedFacilitySizeInformation,
      state: { status, isOpen, tooltip_open },
      props: {
        facility,
        facility: { permissions },
        locale,
        editContentRight
      }
    } = this

    return (
      <React.Fragment>
        {/* TODO: container should be refactored to `src/components/ui/genericComponents/collapseWithHeader/collapseWithHeader.jsx` */}
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">Preise und Kosten</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {permissions.change_charges_list && (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`charges`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip delay={500} placement="top" isOpen={tooltip_open} target={`charges`} toggle={toggleTooltip}>
                  {locale.edit}
                </Tooltip>
              </div>
            )}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            <Table striped responsive bordered>
              <tbody>
                <TableField
                  label={locale.facility.charges.estimatedFacilitySize}
                  value={estimatedFacilitySizeInformation(facility?.estimatedFacilitySize)}
                />
                <TableField
                  label={locale.facility.charges.collection}
                  value={`${convertToEuroString(facility?.chargesList?.collection)} €`}
                />
                <TableField
                  label={locale.facility.charges.certification}
                  value={`${convertToEuroString(facility?.chargesList?.certification)} €`}
                />
                <TableField
                  label={locale.facility.charges.organisation}
                  value={`${convertToEuroString(facility?.chargesList?.organisation)} €`}
                />
                <TableField
                  label={locale.facility.charges.other}
                  value={`${convertToEuroString(facility?.chargesList?.other)} €`}
                />
                <TableField
                  label={locale.facility.charges.discount}
                  value={`${convertToEuroString(facility?.chargesList?.discount)} €`}
                />
                <TableField
                  label={locale.facility.charges.total}
                  value={`${convertToEuroString(facility?.chargesList?.total)} €`}
                />
              </tbody>
            </Table>
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default Charges
