import { Fragment } from 'react'

import { Col, FormFeedback, FormText, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getPropertyFromObject } from '../../helper/helper-functions'

const ValidatedInput = ({
  form_text = '',
  id,
  variables,
  errors,
  locale,
  placeholder,
  onChange,
  label_sm,
  input_sm,
  type,
  pattern,
  max,
  rows,
  style,
  unit_de,
  disabled,
  maxlength,
  autoComplete,
  customLabel,
  children,
  addon,
  step
}) => {
  const GroupClass = unit_de || addon ? InputGroup : Fragment
  const groupComponentProps =
    GroupClass == InputGroup && !!getPropertyFromObject(errors, id) ? { className: 'is-invalid' } : {}

  const error = getPropertyFromObject(errors, id)

  return (
    <Fragment>
      {type === 'checkbox' && (
        <Fragment>
          <Label className="control-label checkbox-label d-block mb-0" for={id}>
            <Input
              className="checkbox-control"
              type="checkbox"
              id={id}
              defaultChecked={getPropertyFromObject(variables, id) === true}
              onChange={onChange}
              invalid={!!getPropertyFromObject(errors, id)}
            />
            <span className="check-icon-container">
              <span className="check-icon text-primary-dark">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            </span>
            <span className="label-text">{customLabel || getPropertyFromObject(locale, id)}</span>
          </Label>
          {!!error && <FormFeedback className={error ? 'd-block' : ''}>{error}</FormFeedback>}
        </Fragment>
      )}
      {type != 'checkbox' && (
        <Fragment>
          <Col sm={label_sm || '2'} className="text-right">
            <Label className="control-label" for={id}>
              {customLabel || getPropertyFromObject(locale, id)}
            </Label>
          </Col>
          <Col sm={input_sm || '4'}>
            <GroupClass {...groupComponentProps}>
              {unit_de && <InputGroupAddon addonType="prepend">{unit_de}</InputGroupAddon>}
              {children || (
                <Input
                  maxLength={maxlength}
                  disabled={disabled}
                  {...style}
                  rows={rows}
                  max={max}
                  pattern={pattern}
                  type={type || 'text'}
                  name={id}
                  id={id}
                  placeholder={placeholder}
                  value={getPropertyFromObject(variables, id) || (type !== 'file' ? '' : undefined)}
                  onChange={onChange}
                  invalid={!!getPropertyFromObject(errors, id)}
                  {...{ autoComplete, step }}
                />
              )}
              {addon && <InputGroupAddon addonType="append">{addon}</InputGroupAddon>}
            </GroupClass>
            {!!error && <FormFeedback className={error ? 'd-block' : ''}>{error}</FormFeedback>}
            {type === 'file' && !!error && <span style={{ color: '#e53012', fontSize: '80%' }}>{error}</span>}
            {!!form_text && <FormText>{form_text}</FormText>}
          </Col>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ValidatedInput
