import React from 'react'

import { Input } from 'reactstrap'

import { withLocale } from 'locale'

const AdditionalInfoOnPricesInput = ({ locale, generalLocale, priceInfoText, onChange }) => {
  return (
    <>
      <p>{locale.priceInfoTextInfo}</p>
      <Input value={priceInfoText || ''} onChange={event => onChange(event.target.value)} />
    </>
  )
}

export default withLocale(
  withLocale(AdditionalInfoOnPricesInput, { key: 'licenseOwners.automatedTendersInformation' }),
  {
    attributeName: 'generalLocale'
  }
)
